import axios from "axios";
import config from "../config";

let vehicles = new Object({

  fetchFilterVehicles: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/vehicles/fetchFilterVehicles",
        data: { filter: filter },
        method: "POST",
      })
        .then((resp) => {
          resolve({ items: resp.data.responseData.vehicles });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  fetchVehiclesHistory: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/vehicles/history",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.vehicles,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchVehicles: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/vehicles/list",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.vehicles,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchNextId: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/vehicles/nextId",
        method: "POST"
      })
        .then(resp => {
          resolve({
            nextId: resp.data.responseData.nextId
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addVehicle: vehicle => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/vehicles/add",
        data: { vehicle: vehicle },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateVehicle: vehicle => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/vehicles/update",
        data: { vehicle: vehicle },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeVehicle: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/vehicles/delete",
        data: { id: id },
        method: "POST"
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  changeDriver: (vehicle_id, driver_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/vehicles/changeDriver",
        data: { vehicle_id: vehicle_id, driver_id: driver_id },
        method: "POST"
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
});

export default vehicles;

import axios from "axios";
import config from "../config";

let products = new Object({
  setAllergenCard: (id, path) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/setAllergenCard",
        data: { id: id, path: path },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchFilterProducts: (filter, category) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/fetchFilter",
        data: { filter: filter, category: category },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.products
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchProducts: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/list",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.products,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchOrderProducts: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/litelist",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.products,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchNextId: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/nextId",
        method: "POST"
      })
        .then(resp => {
          resolve({
            nextId: resp.data.responseData.nextId
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchProduct: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/fetch",
        data: ctx,
        method: "POST"
      })
        .then(resp => {
          resolve({ product: resp.data.responseData.product });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchProductImage: productId => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/image",
        data: { productId: productId },
        method: "POST"
      })
        .then(res => {
          resolve({ image: res.data.responseData });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addProduct: product => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/add",
        data: { product: product },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateProduct: product => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/update",
        data: { product: product },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateProductImage: (productId, content) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/updateImage",
        data: { productId: productId, content: content },
        method: "POST"
      })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeProduct: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/delete",
        data: { id: id },
        method: "POST"
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchMeasureUnits: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/fetchMeasureUnits",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.measureUnits,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  sendAllergen: context => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/sendAllergen",
        data: { context },
        method: "POST"
      })
        .then(resp => {
          resolve(resp.data.responseData);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchCategories: ctx => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/fetchCategories",
        data: { ctx: ctx },
        method: "POST"
      })
        .then(resp => {
          resolve({
            items: resp.data.responseData.categories,
            totalCount: resp.data.responseData.count
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addCategory: category => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/addCategory",
        data: { category: category },
        method: "POST"
      })
        .then(resp => {
          resolve({ lastInsertId: resp.data.responseData.lastInsertId });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateCategory: category => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/updateCategory",
        data: { category: category },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateCategoryAvailability: category => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/updateCategoryAvailability",
        data: { category: category },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateCategoryLastTime: category => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/updateCategoryLastTime",
        data: { category: category },
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeCategory: id => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/products/deleteCategory",
        data: { id: id },
        method: "POST"
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
});

export default products;
